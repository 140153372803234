import Axios from "lib/Axios";

export const viewAllProjectCustomers = ({ body_data, params }) =>
  Axios.post(`/api/admin/project-customer/list?${params}`, body_data).then(
    (res) => {
      return res.data;
    }
  );

const endpoints = {
  addCustomerToProject: (data) => ({
    method: "post",
    url: "/api/admin/project-customer/create",
    data,
  }),
  updateProjectCustomer: (id, data) => ({
    method: "put",
    url: `/api/admin/project-customer/update/${id}`,
    data,
  }),
  deleteCustomer: (id) => ({
    method: "delete",
    url: `/api/admin/project-customer/trash/${id}`,
  }),
};

export const { addCustomerToProject, updateProjectCustomer, deleteCustomer } =
  endpoints;

export const exportProjectCustomer = ({ body_data }) =>
  Axios.post("/api/admin/project-customer/export", body_data).then(
    (res) => res.data
  );

export const importSample = () =>
  Axios.get("/api/admin/lead/sample?type=xlsx").then((res) => res.data);

export const restoreLead = ({ id }) =>
  Axios.get(`/api/admin/lead/restore/${id}`).then((res) => res.data);

export const viewLead = ({ id }) =>
  Axios.get(`/api/admin/lead/show/${id}`).then((res) => res.data);

export const allLeadUtils = () =>
  Axios.get("/api/admin/base/lead-utils").then((res) => res.data);

export const updateProjectCustomerStatus = ({ body_data }) =>
  Axios.post("/api/admin/project-customer/change/status", body_data).then(
    (res) => res.data
  );

export const viewNote = ({ id }) =>
  Axios.get(`/api/admin/project-customer/note/${id}`).then((res) => res.data);

export const createNote = ({ body_data }) =>
  Axios.post("/api/admin/note/create", body_data).then((res) => res.data);

export const deleteNote = ({ id }) =>
  Axios.delete(`/api/admin/note/delete/${id}`).then((res) => res.data);

export const viewAttachment = ({ id }) =>
  Axios.get(`api/admin/project-customer/attachment/${id}`).then(
    (res) => res.data
  );

export const uploadAttachment = ({ body_data, id, setProgress }) =>
  Axios.post(`/api/admin/project-customer/attachment/upload/${id}`, body_data, {
    onUploadProgress: (progressEvent) => {
      let percentComplete = progressEvent.loaded / progressEvent.total;
      percentComplete = parseInt(percentComplete * 100);
      setProgress(percentComplete);
    },
  }).then((res) => res.data);

export const deleteAttachment = ({ id }) =>
  Axios.delete(`/api/admin/attachment/delete/${id}`).then((res) => res.data);

export const viewActivity = ({ id }) =>
  Axios.get(`/api/admin/project-customer/activity/${id}`).then(
    (res) => res.data
  );

export const viewLeadSummary = () =>
  Axios.get(`api/admin/project-customer/summery`).then((res) => res.data);

export const importProject = ({ file }) =>
  Axios.post("/api/admin/project-customer/import", file).then(
    (res) => res.data
  );

export const projectCustomerBulkAction = ({ body_data }) =>
  Axios.post("/api/admin/project-customer/bulk/action", body_data).then(
    (res) => res.data
  );

export const viewTasks = ({ id, params }) =>
  Axios.get(`/api/admin/project-customer/task/${id}?${params}`).then(
    (res) => res.data
  );

export const addTask = ({ body_data }) =>
  Axios.post("/api/admin/task/create", body_data).then((res) => res.data);

export const updateTask = ({ id, body_data }) =>
  Axios.post(`/api/admin/task/update/${id}`, body_data).then((res) => res.data);

export const deleteTask = ({ id }) =>
  Axios.delete(`/api/admin/task/delete/${id}`).then((res) => res.data);

export const addSponsor = ({ body_data }) =>
  Axios.post("/api/admin/project-customer/sponsor/create", body_data).then(
    (res) => res.data
  );

export const updateSponsor = ({ id, body_data }) =>
  Axios.put(`/api/admin/project-customer/sponsor/update/${id}`, body_data).then(
    (res) => res.data
  );

export const deleteSponsor = ({ id }) =>
  Axios.delete(`/api/admin/project-customer/sponsor/delete/${id}`).then(
    (res) => res.data
  );

export const updateFollowupDate = ({ body_data }) =>
  Axios.post(`/api/admin/project-customer/followupdate`, body_data).then(
    (res) => res.data
  );

export const viewEmbassyDetails = ({ id }) =>
  Axios.get(`/api/admin/project-customer/embassy/details/${id}`).then((res) => {
    return res.data;
  });

export const embassyAddEdit = ({ body_data }) =>
  Axios.post("/api/admin/project-customer/embassy", body_data).then(
    (res) => res.data
  );

export const viewAdmissions = ({ id }) =>
  Axios.get(`/api/admin/project-customer/admission/info/${id}`).then(
    (res) => res.data
  );

export const project_customer_alart = ({ id }) =>
  Axios.get(`/api/admin/project-customer/alert/${id}`).then((res) => res.data);

export const addAdmission = ({ body_data }) =>
  Axios.post("/api/admin/project-customer/admission", body_data).then(
    (res) => res.data
  );

export const updateAdmission = ({ body_data, id }) =>
  Axios.put(
    `/api/admin/project-customer/admission/update/${id}`,
    body_data
  ).then((res) => res.data);

export const deleteAdmission = ({ id }) =>
  Axios.delete(`/api/admin/project-customer/admission/delete/${id}`).then(
    (res) => res.data
  );

export const viewProjectCustomerSummary = ({ body_data }) =>
  Axios.post("/api/admin/project-customer/summery", body_data).then(
    (res) => res.data
  );

export const sponsorProfessions = () =>
  Axios.get("/api/admin/base/project-customer-sponsor-job").then(
    (res) => res.data
  );

export const sponsorProfessionsFields = () =>
  Axios.get("/api/admin/base/project-customer-sponsor-job-field").then(
    (res) => res.data
  );

export const viewInvoices = ({ body_data, params }) =>
  Axios.post(`/api/admin/invoice/list?${params}`, body_data).then((res) => {
    return res.data;
  });

export const viewProjectCustomerInvoices = ({ body_data, params }) =>
  Axios.post(`/api/admin/invoice-list?${params}`, body_data).then((res) => {
    return res.data;
  });

export const createInvoice = ({ body_data }) =>
  Axios.post("/api/admin/invoice/create", body_data).then((res) => res.data);

export const updateInvoice = ({ body_data, id }) =>
  Axios.put(`/api/admin/invoice/update/${id}`, body_data).then(
    (res) => res.data
  );

export const deleteInvoice = ({ id }) =>
  Axios.delete(`/api/admin/invoice/trash/${id}`).then((res) => res.data);
