import { Box } from "@mui/material";
import useApiCall from "hooks/useApiCall";
import usePermissions from "hooks/usePermissions";
import { useEffect, useState } from "react";
import Axios from "lib/Axios";
import InfiniteHeader from "./InfiniteHeader";
import InfiniteTable from "./InfiniteTable";

const viewAllInfinite = ({ params }) =>
  Axios.get(`/api/admin/infinite/list?${params}`).then((res) => res.data);

const Infinite = () => {
  const permissions = usePermissions("staff");
  const { data, loading, fetchData: fetchAllStaffs } = useApiCall();
  const [allStaffs, setAllStaffs] = useState([]);
  const [reload, setReload] = useState(false);
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    current_page: 1,
    per_page: 10,
  });

  useEffect(() => {
    fetchAllStaffs(
      viewAllInfinite,
      {
        params: `page=${pagination?.current_page}&limit=${pagination?.per_page}&search=${search}`,
      },
      false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, search, pagination?.current_page, pagination?.per_page]);

  useEffect(() => {
    if (data) {
      const { data: allData, ...rest } = data;
      setAllStaffs(allData);
      setPagination(rest);
    }
  }, [data]);

  return (
    <Box>
      <InfiniteHeader
        {...{
          setSearch,
          setPagination,
          pagination,
          permissions,
          search,
        }}
      />
      <InfiniteTable
        allStaffs={allStaffs}
        pagination={pagination}
        setPagination={setPagination}
        loading={loading}
        setReload={setReload}
      />
    </Box>
  );
};

export default Infinite;
