import { Box, Skeleton, Typography } from "@mui/material";
import useApi from "hooks/useApi";
import { useEffect, useState } from "react";

const InvoiceSummary = ({ customer_id, project_id }) => {
  const { loading, fetchData } = useApi();
  const [expenseVsIncome, setExpenseVsIncome] = useState([]);

  useEffect(() => {
    const getActivityList = async () => {
      const endpoint = {
        method: "post",
        url: "/api/admin/expense-vs-income?",
        data: {
          customer_id: customer_id ? [customer_id] : [],
          project_id: project_id ? [project_id] : [],
        },
      };
      const result = await fetchData(endpoint, false);
      if (result?.success) {
        setExpenseVsIncome(result?.data?.data[0]);
      }
    };
    getActivityList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
            gap: "20px",
            ml: "20px",
            mb: "20px",
          }}
        >
          {Array(4)
            .fill(0)
            .map((_, i) => (
              <Skeleton key={i} height={140} />
            ))}
        </Box>
      ) : (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
            gap: "20px",
            ml: "20px",
            mb: "20px",
          }}
        >
          {[
            {
              label: "Total Invoice",
              value: expenseVsIncome?.total_invoice,
            },
            {
              label: "Invoice Paid Amount",
              value: expenseVsIncome?.inv_paid_amount,
            },
            {
              label: "Invoice Due Amount",
              value: expenseVsIncome?.inv_due_amount,
            },
            {
              label: "Total Expense",
              value: expenseVsIncome?.total_expense,
            },
          ].map((item, i) => (
            <Box
              key={i}
              sx={{
                bgcolor: `${item?.color}27`,
                padding: "20px",
                borderRadius: "5px",
                boxShadow: "2px 1px 3px #e5e5e5",
              }}
            >
              <Typography variant="h4">{item?.value || 0}</Typography>
              <Typography fontSize={14} color="#666">
                {item?.label}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};

export default InvoiceSummary;
