import { Box, Button } from "@mui/material";
import DateTimePicker from "components/ui/dateTimePicker";
import InputField from "components/ui/inputField";
import Select from "components/ui/select";
import {
  addAdmission,
  updateAdmission,
} from "features/projectCustomers/api/projectCustomer";
import CheckDateBeforeToday from "features/projectCustomers/utils/CheckDateBeforeToday";
import { useFormik } from "formik";
import useBase from "hooks/useBase";
import { setLoading } from "lib/Reducer/loadingSlice";
import { useEffect } from "react";
import { BiLeftArrowAlt } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as yup from "yup";

const AddAdmission = ({
  customer_id,
  project_id,
  project_customer_id,
  setAddAdmission,
  setReload,
  currAdmission,
}) => {
  let education_level = useSelector(
    (state) => state.base.base_data.educationLevels
  );
  const dispatch = useDispatch();
  const initialValues = {
    admission_status_id: "",
    country_id: "",
    university_id: "",
    subject_id: "",
    text: "",
    start_class: null,
    last_date_of_enroll: null,
    student_id: "",
    education_level: "",
    apply_through_id: "",
  };

  const validationSchema = yup.object({
    admission_status_id: yup
      .string("")
      .required("Status is Required")
      .nullable(),
    country_id: yup.string("").required("Country is Required").nullable(),
    university_id: yup.string("").required("University is Required").nullable(),
    subject_id: yup.string("").required("Subject is Required").nullable(),
  });
  const onSubmit = async (data) => {
    dispatch(setLoading(true));
    data.customer_id = customer_id;
    data.project_id = project_id;
    data.project_customer_id = project_customer_id;

    try {
      let result;
      if (currAdmission?.id) {
        result = await updateAdmission({
          body_data: data,
          id: currAdmission.id,
        });
      } else {
        result = await addAdmission({
          body_data: data,
        });
      }
      if (result.success) {
        toast.success(result.message);
        setAddAdmission(false);
        setReload((pre) => !pre);
      } else {
        toast.error(result.message);
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getInitialValues = (data) => {
    if (data?.id) {
      let obj = {};
      for (const key in initialValues) {
        obj[key] = data[key];
      }
      return obj;
    } else return initialValues;
  };

  const formik = useFormik({
    initialValues: getInitialValues(currAdmission),
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });
  const { updateBase, base } = useBase();
  const {
    admissionStatuses,
    countries,
    universities,
    subjects,
    projectCustomerAdmissionApplyThroughs,
  } = base;

  useEffect(() => {
    updateBase([
      "admissionStatuses",
      "countries",
      "universities",
      "subjects",
      "projectCustomerAdmissionApplyThroughs",
    ]);
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          startIcon={<BiLeftArrowAlt />}
          onClick={() => setAddAdmission(false)}
        >
          View Admissions
        </Button>
      </Box>
      <Box p="20px 0">
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "15px",
            mb: "15px",
          }}
        >
          <InputField name="student_id" label="Student Id" formik={formik} />
          <Select
            options={admissionStatuses}
            label="Admission Status *"
            value={formik?.values?.admission_status_id}
            onChange={(value) =>
              formik.setFieldValue("admission_status_id", value)
            }
            selectProps={{ id: "id", label: "name" }}
          />
          <DateTimePicker
            name="last_date_of_enroll"
            label="Last Date of Enroll"
            formik={formik}
          />
          <CheckDateBeforeToday givenDate={currAdmission?.start_class}>
            <DateTimePicker
              name="start_class"
              label="Start Class"
              formik={formik}
            />
          </CheckDateBeforeToday>
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "15px",
            mb: "15px",
          }}
        >
          <Select
            options={countries}
            label="Country *"
            value={formik?.values?.country_id}
            onChange={(value) => formik.setFieldValue("country_id", value)}
            selectProps={{ id: "id", label: "name" }}
          />
          <Select
            options={education_level}
            label="Education Level"
            name="education_level"
            formik={formik}
          />
          <Select
            disabled={!formik?.values?.country_id}
            options={
              formik.values.country_id
                ? universities?.filter(
                    (university) =>
                      university?.country_id === formik.values.country_id
                  )
                : universities
            }
            label="University *"
            value={formik?.values?.university_id}
            onChange={(value) => formik.setFieldValue("university_id", value)}
            selectProps={{ id: "id", label: "name" }}
          />

          <Select
            name="apply_through_id"
            options={projectCustomerAdmissionApplyThroughs}
            label="Apply Through"
            formik={formik}
            selectProps={{ id: "id", label: "name" }}
          />

          <Select
            disabled={!formik?.values?.country_id}
            options={subjects}
            label="Subject *"
            value={formik?.values?.subject_id}
            onChange={(value) => formik.setFieldValue("subject_id", value)}
            selectProps={{ id: "id", label: "name" }}
          />
          <InputField
            sx={{ gridColumn: "span 2" }}
            name="text"
            label="Note"
            formik={formik}
            multiline
            rows={3}
            // InputLabelProps={{ shrink: formik.values.text ? true : false }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
            mt: "20px",
          }}
        >
          <Button
            // sx={{ padding: "25px" }}
            variant="solid"
            onClick={formik.submitForm}
            fullWidth
            disabled={!(formik.dirty && formik.isValid)}
          >
            Save Admission
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default AddAdmission;
