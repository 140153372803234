import { Grid } from "@mui/material";
import SearchBox from "components/ui/searchBox";
import { useDebounce } from "hooks/useDebounce";
import { useEffect, useState } from "react";

const InfiniteHeader = ({
  setOpenSidebar,
  setSearch,
  setPagination,
  pagination,
  permissions,
  search,
}) => {
  const [searchText, setSearchText] = useState();
  const debouncedText = useDebounce(searchText, 1000);

  useEffect(() => {
    setSearch(debouncedText || "");
    setPagination({ ...pagination, current_page: 1 });
  }, [debouncedText]);

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      p="15px 20px"
    >
      <Grid item>
        {!!permissions.list && (
          <SearchBox
            handleSearch={(text) => setSearchText(text)}
            placeholder={"Search in table.."}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default InfiniteHeader;
