import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import TableSkeleton from "components/skeletons/TableSkeleton";
import TablePagination from "components/tablePagination";

const InfiniteTable = ({
  allStaffs,
  pagination,
  setPagination,
  loading,
  setReload,
}) => {
  const rows = allStaffs;

  const columns = [
    { id: "first_name", label: "First Name" },
    { id: "last_name", label: "Last Name" },
    { id: "email", label: "Email" },
    { id: "phone", label: "Phone" },
    { id: "message", label: "Message" },
  ];

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      {!loading ? (
        <TableContainer
          sx={{ height: "calc(100vh - 198px)", overflowX: "auto" }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow
                sx={{
                  "& .MuiTableCell-sizeMedium": {
                    padding: "10px 0px 10px 20px",
                  },
                }}
              >
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={column.style}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    key={row.code}
                    sx={{
                      "& .MuiTableCell-sizeMedium": {
                        padding: "13px 0px 13px 20px",
                      },
                    }}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={column.style}
                        >
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableSkeleton columns={columns} tableHeight="calc(100vh - 198px)" />
      )}
      <TablePagination
        pagination={pagination}
        setPagination={setPagination}
        setReload={setReload}
      />
    </Paper>
  );
};

export default InfiniteTable;
