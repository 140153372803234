import { Delete, UploadFile } from "@mui/icons-material";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import RightDrawer from "components/rightDrawer/RightDrawer";
import SuccessAnimation from "components/ui/successAnimation";
import { setLoading } from "lib/Reducer/loadingSlice";
import { useState } from "react";
import { FcFile } from "react-icons/fc";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { importProject } from "../api/projectCustomer";

const Import = ({ openDrawer, setOpenDrawer }) => {
  const [selectedFile, setSlectedFile] = useState(null);
  const [totalImported, setTotalImported] = useState(null);
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  const handleUploadAttachment = async (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      setSlectedFile(file);
      setError("");
    }
  };

  const handleSubmit = async (data) => {
    dispatch(setLoading(true));
    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const res = await importProject({
        file: formData,
      });
      if (res.success) {
        setTotalImported(res.data.total_upload);
      } else {
        toast.error(res.message);
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

  const formatFileName = (text) => {
    if (text.length > 20) {
      const textArr = text.split(".");
      const modifiedText =
        textArr[0].substring(0, 20) + "..." + textArr[textArr.length - 1];
      return modifiedText;
    } else return text;
  };

  return (
    <RightDrawer
      {...{
        openDrawer,
        setOpenDrawer,
        width: "400px",
        headingLeft: "Import Project",
        headingRight: "",
      }}
    >
      {totalImported === null ? (
        <Stack gap="20px" p="20px">
          {selectedFile ? (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "50px auto 100px",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <FcFile style={{ fontSize: "40px" }} />
              </Box>
              <Box>
                <Typography variant="h6">
                  {formatFileName(selectedFile?.name)}
                </Typography>
                <Typography sx={{ fontSize: "12px" }}>
                  {(selectedFile?.size / 1024).toFixed(0)}KB
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "10px",
                }}
              >
                <IconButton
                  onClick={() => {
                    setError("");
                    setSlectedFile(null);
                  }}
                >
                  <Delete />
                </IconButton>
              </Box>
            </Box>
          ) : (
            <Button component="label" variant="outlined">
              <input
                type="file"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                hidden
                onChange={(e) => handleUploadAttachment(e)}
              />
              Select Filte
            </Button>
          )}
          <Box>
            {error && (
              <Typography sx={{ color: "red", mb: "7px" }}>{error}</Typography>
            )}
            <Button
              fullWidth
              startIcon={<UploadFile />}
              variant="solid"
              disabled={!selectedFile}
              onClick={handleSubmit}
            >
              Import
            </Button>
          </Box>
        </Stack>
      ) : (
        <Stack sx={{ padding: "20px" }} gap="10px" alignItems="center">
          <SuccessAnimation />
          <Typography variant="h5" mb="20px">
            Total {totalImported} data imported
          </Typography>
          <Button
            fullWidth
            variant="solid"
            onClick={() => {
              setTotalImported(null);
              setSlectedFile(null);
            }}
          >
            Import More
          </Button>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => setOpenDrawer(false)}
          >
            Close
          </Button>
        </Stack>
      )}
    </RightDrawer>
  );
};

export default Import;
